import styled from "styled-components"


export const HoverMenuContent = styled.div`
  display: block;
  position: relative;
  background-color: rgba(0,0,0,0.5);
  border-bottom: 0.2vw solid;
  color: white;
  min-width: 10vw;
  z-index: 1;
  &:last-child{
    border-bottom:none;
  }
`
export const HoverMenu = styled.div`
position: absolute;
font-size: min(1.2vw,2.4vh);
right: max(-2vw,-4vh);
min-width: min(10vw,-20vh);
visibility: hidden;
font-family:  'Rajdhani';
text-decoration: none;
    transition: visibility 0s 0.1s;
  &:hover{
    visibility: visible;
    ${HoverMenuContent}{
      visibility: visible;
    }
  }
`

export const HoverMenuLink = styled.a`
    color: white;
    padding: min(0.5vw,1vh);
    font-size: min(1.2vw,2.4vh);
    margin: 0;
    text-decoration: none;
    display: block;
`
