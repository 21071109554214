import styled from 'styled-components';
// import BackgroundImage from '../assets/MissionX_Logo_wBG.jpg';
// import { ButtonPrimary } from './UIElements/UIComponent';
import UIOverlayFrame from './UIElements/UIOverlayFrame';

// const Background = styled.div`
// background-image: url(${BackgroundImage});
// width: 100%;
// height: 100%;
// top: 0px;
// left:0px;
// background-repeat: round;
// background-size: cover;
// `
const PageContent = styled.div`
color:white;
font-family: "Chakra Petch", sans-serif;
text-align: center;
padding:50px;
font-size: 1.2em;
`
const PageWrapper = styled.div`
width: 100vw;
height: 56.25vw;
margin-top: auto;
margin-bottom: auto;
max-width: 177.777777777777vh;
max-height: 100vh;
position: absolute;
left: 0;
top: 0;
bottom: 0;
right: 0;
margin-left: auto;
margin-right: auto;
`
const MaintenancePage = () => {

    return (
        <PageWrapper>
            {/* <Background> */}
                <UIOverlayFrame title={"NOTICE"}>
                    <PageContent>
                    The adventure with MissionX has reached its final chapter. 
                    <br /><br />
                    Thank you to everyone who joined us along the way.

                    </PageContent>
                </UIOverlayFrame>
            {/* </Background> */}
        </PageWrapper>
    )
}

export default MaintenancePage