import React from "react";
import styled from "styled-components";
import UIBackground from '../../assets/UI_Frame_Background.png'
import ExitBackground from '../../assets/Exit_Button.png'
import FontScaleComponent from "./FontScaleComponent";


const BackgroundOverlay = styled.div`
width: 100%;
height: 100%;
position: absolute;
background-image: url(${UIBackground});
background-size:contain;
color: white;
`

const HeaderPlacement = styled.div`
position: absolute;
    top: 22.5%;
    text-align: center;
    font-family: 'spaceranger';
    font-size: min(2.6vw,5.2vh);
    width: 20%;
    left: 40%;
    right: 40%;
    text-align: center;
    letter-spacing: min(0.6vw,1.2vh);
`
const BodyPlacement = styled.div`
position: absolute;
    width: 45.5%;
    left: 27%;
    height: 48%;
    top: 29%;
    overflow-y: scroll;
    font-family: 'Rajdhani';
/* SCROLLBAR */
::-webkit-scrollbar {
    width: min(0.6vw,1.2vh);
    background-color:transparent;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #1e82cb;
    border: 1px solid #1e82cb;
}
`
const ExitButton = styled.img`
    width: 4%;
    left: 62%;
    position: absolute;
    top: 16.2%;
    &:hover{
        cursor:pointer;
    }
`
const UIOverlayFrame = (props: any) => {

    return (
        <BackgroundOverlay style={props.style}>
            <HeaderPlacement>
                <FontScaleComponent startAtChar={5} changePerChar={3}>
                    {props.title}
                </FontScaleComponent>
            </HeaderPlacement>
            {props.exit_click &&
                <ExitButton onClick={(e) => { props.exit_click(); e.stopPropagation(); }} src={ExitBackground} />
            }

            <BodyPlacement>
                {props.children}
            </BodyPlacement>
        </BackgroundOverlay>
    );
};

export default UIOverlayFrame;
