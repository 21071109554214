type EndpointsGlobal = {
    MISSION_API_URL: string;
    LEGACY_API_URL: string;
    AUTH_URL: string;
    INFURA_MAINNET_URL: string;
    SCENE_API_URL: string;
    TOKEN_API_URL: String;
    ADMIN_API_URL: string;
    ITEM_API_URL: string;
};

export const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const ENDPOINTS: EndpointsGlobal = {
    LEGACY_API_URL: "https://billionaires.io/api",
    MISSION_API_URL: BASE_URL + "/api/mission",
    SCENE_API_URL: BASE_URL + "/api/scene",
    ADMIN_API_URL: BASE_URL + "/api/admin_tools",
    AUTH_URL: BASE_URL + "/auth",
    TOKEN_API_URL: BASE_URL + "/api/token",
    ITEM_API_URL: BASE_URL + "/api/item",
    INFURA_MAINNET_URL: "https://mainnet.infura.io/v3/9b27092ba8f146ae965287fe7776090a"
};


export default ENDPOINTS;
