import { auth } from "service/firebase_config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { ReactNode, useContext, useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { useEthers } from "@usedapp/core";
import 'assets/font/spaceranger.ttf';
import 'assets/font/Bebas-Regular.ttf';
import 'assets/font/digital_counter_7.ttf';
import 'App.css';
import React from "react";
import { UnityWrapper } from "components/UnityWrapper";
import GLOBALS from 'service/EndpointsGlobal';
import settingsIcon from "assets/icons/settings_icon.png";
import bbit from "assets/icons/bbit_icon.png";
import XP from "assets/icons/xp_icon.png";
import inventory from "assets/icons/inventory_icon.png";
import person from "assets/icons/agent_icon.png";
import { IUserProfile_BBStats } from "missionx-shared";
import { UserProfileWrapper } from "service/UserProfile";
import { authorizedGetFetch } from "service/APIService";
import UserInventory from "components/Header/UserInventory";
import { StagingWarning } from "components/UIElements/UIComponent";
// import EnterGameScreen from "components/EnterGameScreen";
import styled from "styled-components";
import { TopBarBalance } from "components/Header/TopBarElements";
import { Logout, UserSettings } from "components/Header/UserSettings";
import UserBillionaires from "components/Header/Billionaires/UserBillionaires";
import { ShowOnHover } from "components/Header/ShowOnHover";
// import MaintenancePage from "components/MaintenancePage";
import ShutDown from "components/ShutDown";

const TopBar = styled.div`
  background: red;
  position: absolute;
  height: min(2.5vw,5vh);
  width: min(100vw,200vh);
  z-index: 100;
  background-color: rgba(0,0,0,0.5);
  text-align: center;
  display:flex;
  flex-direction:row;
  justify-content:center;
  max-width: 100%;
`
const BalanceWrapper = styled.div`
  color: white;
  margin-left: min(1vw,2vh);
  margin-right: min(1vw,2vh);
  font-size: min(1.5vw,3vh);
  display: flex;
  flex-direction:row;
  line-height: min(2.5vw,4vh);
  font-family: 'Digital Counter 7';
`
const BalanceIcon = styled.img`
  margin-right: min(0.35vw,0.7vh);
  width:min(2.5vw,5vh);
  height:min(2.5vw,5vh);
`
export const ProfileContext = React.createContext<UserProfileWrapper | undefined>(undefined);

const App = ({ children }: any) => {
  const Profile = useContext(ProfileContext);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const { account } = useEthers();
  const [profile, setProfile] = useState<IUserProfile_BBStats>();
  const [gameEntered] = useState(false);
  const [popupUI, setPopupUI] = useState<ReactNode | null>();
  const forceUpdate = useForceUpdate();
  function useForceUpdate() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
  }

  useEffect(() => {
    if (loading) console.log("loading")
    if (loading) return;
    // const ANONYMOUS_URLS = ['login', 'maintenance'];
    // if ((!user) &&
    //   !ANONYMOUS_URLS.some(str => window.location.href.includes(str))) {
    //   window.location.href = '/login';
    // }
    // if ((!account) &&
    //   !ANONYMOUS_URLS.some(str => window.location.href.includes(str))) {
    //   window.location.href = '/login';
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading, navigate]);

  useEffect(() => {
    if (user && account && user.uid.toLowerCase() === account.toLowerCase()) {
      updateBalances();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, account])

  useEffect(() => {
    //detect wallet change or missing and sign out
    if (user && (!account || user?.uid !== account?.toLowerCase())) {
      Logout(Profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const updateBalances = () => {
    authorizedGetFetch(`${GLOBALS.AUTH_URL}/accountStatus/${user?.uid}`)
      .then(res => res?.json())
      .then(async data => {
        if (!data.settings) {
          signOut(auth);
          return;
        }
        setProfile(data);
      }).catch(v => {
        signOut(auth);
      });//Todo - add error for 401 response and logout (No BB tokens owned)
  }

  return (
    <div className="App">
      <ShutDown />
      {/* {!gameEntered &&
        <ShutDown/>
      }
      {
        gameEntered &&
        <>
          {user && profile &&
            <ProfileContext.Provider value={new UserProfileWrapper(profile, updateBalances, forceUpdate, setPopupUI)}>
              <UnityWrapper>
                <TopBar>
                  {process.env.REACT_APP_ENVIRONMENT_WARNING &&
                    <StagingWarning>
                      {process.env.REACT_APP_ENVIRONMENT_WARNING}
                    </StagingWarning>
                  }

                  <ShowOnHover>
                    <TopBarBalance tooltip={"Billionaires in Wallet"} balance={profile?.billionaire_count} icon={person} />
                    <UserBillionaires onClick={(e: any) => { e.stopPropagation(); }}></UserBillionaires>
                  </ShowOnHover>
                  <TopBarBalance tooltip={"bbit Balance"} balance={profile?.bit_balance} icon={bbit} />
                  <TopBarBalance tooltip={"Wallet XP"} balance={profile?.XP} icon={XP} />

                  <BalanceWrapper style={{ position: "absolute", right: "5vw" }}>
                    <ShowOnHover>
                      <BalanceIcon src={inventory} />
                      <UserInventory onClick={(e: any) => { e.stopPropagation(); }} currentPopup={popupUI}></UserInventory>
                    </ShowOnHover>
                  </BalanceWrapper>

                  <BalanceWrapper style={{ position: "absolute", right: "1vw" }}>
                    <ShowOnHover>
                      <BalanceIcon src={settingsIcon} />
                      <UserSettings profile={profile} />
                    </ShowOnHover>
                  </BalanceWrapper>
                </TopBar>
                <>
                  {children}
                  {popupUI && <UnityWrapper onClick={(e) => { e.stopPropagation(); }}>{popupUI}</UnityWrapper>}
                </>
              </UnityWrapper>
            </ProfileContext.Provider>
          }
          {!(profile && user) && children}
        </>
      } */}
    </div >
  );
}

export default App