import { Slider } from "@mui/material";
import styled from "styled-components";
import ButtonBackground from '../../assets/Start_Button.png';

export const UITitle = styled.div`
    font-family: "Rajdhani", sans-serif;
    font-weight:bold;
    font-size:min(1.8vw,3.6vh);
    text-transform: uppercase;
    text-align:center;
`

export const UITitleLarge = styled.div`
    font-family: "Bebas", sans-serif;
    font-weight:bold;
    font-size:28px;
    text-align:center;
    margin:10px;
    text-transform: uppercase;
    text-shadow: rgb(255 0 0) 0px 0px 10px, rgb(0 0 255) 0px 0px 15px;
`

export const UIExit = styled.div`
    float:right;
    font-weight:bold;
    position: absolute;
    right:10px;
    top:0px;
    &:hover{
    cursor: pointer;
    }
`

export const UIText = styled.div`
    font-family: "Rajdhani", sans-serif;
    font-size: min(1.5vw,3vh);
`

export const UiBodyWrapper = styled.div`
    margin: 20px;
    justify-content: center;
    text-align:center;
    height:100%;
`
export const VerticalCenter = styled.div`
    display: flex;
    justify-content:center;
    flex-direction:column;
    height:100%;
    align-items:center;
    text-align:center;
`
export const PointerOnHover = styled.div`
&:hover{
    cursor:pointer;
}
`
export const ButtonPrimary = styled.button`
  background-size: cover;
background-repeat: round;
background-color: transparent;
    font-family: "Bebas", sans-serif;
    background-image: url(${ButtonBackground});
    width: min(16vw,32vh);
    height: min(4.5vw,9vh);
    border: none;
    border-radius: 12px;
    margin-left:auto;
    margin-right:auto;
    color: white;
    font-size: min(1.6vw,3.2vh);
    border:0;
    &:hover{
        cursor:pointer;
    }
`
export const Column = styled.div`
display:flex;
flex-direction:column;
`
export const Row = styled.div`
display:flex;
flex-direction:row;
`
export const ButtonSecondary = styled.button`
    background-size: cover;
    background-repeat: round;
    background-color: var(--uicolor-blue);
    font-family: "Bebas", sans-serif;
    width: min(16vw,32vh);
    height: min(4.5vw,9vh);
    border: none;
    border-radius: min(1vw,1.78vh);
    margin-left:auto;
    margin-right:auto;
    color: white;
    font-size: min(1.6vw,3.2vh);
    border:0;
    &:hover{
        cursor:pointer;
    }
`
export const CustomSlider = styled(Slider)(({ theme }) => ({
    "& .MuiSlider-thumb": {
        height: 'min(1.8vw,3.6vh)',
        width: 'min(1.8vw,3.6vh)'
    },
    "& .MuiSlider-track": {
        height: 'min(0.5vw,1vh)'
    },
}));

export const ButtonPrimaryXL = styled.button`
    background-size: cover;
    background-repeat: round;
    background-color: transparent;
    font-family: "Bebas", sans-serif;
    background-image: url(${ButtonBackground});
    width: min(30vw,60vh);
    height: min(7vw,14vh);
    border: none;
    border-radius: 12px;
    margin-left:auto;
    margin-right:auto;
    color: white;
    font-size: min(2.4vw,4.8vh);
    border:0;
    &:hover{
        cursor:pointer;
    }
`
export const LoadingCenter = styled.div`
    text-align:center;
    margin-top: min(10vw,20vh);
    font-family: "Bebas", sans-serif;
    font-weight:bold;
    font-size:min(1.5vw,3vh);
`

export const StagingWarning = styled.div`
    color: red;
    font-size: min(1.8vw, 3.6vh);
    position: absolute;
    left: 0px;
`