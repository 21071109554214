import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    serviceAccountId: process.env.REACT_APP_SERVICE_ACCOUNT_ID
};


const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// Initialize Performance Monitoring and get a reference to the service
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const perf = getPerformance(app);

export const auth = getAuth(app);