import { HoverMenu, HoverMenuContent, HoverMenuLink } from "../UIElements/HoverMenu";
import ToggleableButton from "../UIElements/ToggleableButton";
import { CustomSlider } from "../UIElements/UIComponent";
import { IUserProfile, Permissions } from "missionx-shared";
import { updateProfileSetting } from "../../service/APIService";
import { useContext, useEffect, useState } from "react";
import { AdjustMusicVolume, AdjustSFXVolume, GameSounds, GameSoundsType } from "../../service/Sounds";
import { signOut } from "firebase/auth";
import { auth } from "../../service/firebase_config";
import { useEthers } from "@usedapp/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { ProfileContext } from "../../App";
import { UserProfileWrapper } from "../../service/UserProfile";

export const Logout = async (Profile: UserProfileWrapper | undefined) => {
    if (Profile?.CurrentUnityContext) {
        console.log("Unloading Unity");
        await Profile?.CurrentUnityContext.quitUnityInstance();
    }
    window.location.href = "/login";
    signOut(auth);
}

export const UserSettings = (props: { profile: IUserProfile }) => {
    const [user] = useAuthState(auth);
    const [currentSFXVolume, setCurrentSFXVolume] = useState<number>(0);
    const [currentMusicVolume, setCurrentMusicVolume] = useState<number>(0);
    const profile = props.profile;
    const [changingQuality, setChangingQuality] = useState(false);
    const { account } = useEthers();
    const [soundPlaying, setSoundPlaying] = useState(false);
    const [gameSounds] = useState<GameSoundsType>(GameSounds);
    const Profile = useContext(ProfileContext);
    gameSounds.BackgroundTrack.loop = true;


    //This is needed because play() does not work on chrome browsers if the user has not interacted with the document
    //Continually retry starting the sound playback until it works
    const checkSound = () => {
        if (!soundPlaying) {
            const soundPromises: Array<Promise<void>> = [];
            soundPromises.push(gameSounds.BackgroundTrack.play());
            soundPromises.push(gameSounds.StartupTrack.play());
            Promise.all(soundPromises).then(v => {
                setSoundPlaying(true);
            }).catch(e => {
                setTimeout(() => { checkSound() }, 100)
            });
        }
    }
    useEffect(() => {
        if (user && profile) {
            checkSound();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, profile])

    useEffect(() => {
        AdjustSFXVolume(currentSFXVolume / 100, profile?.settings.sound_enabled !== 'true');
        AdjustMusicVolume(currentMusicVolume / 100, profile?.settings.sound_enabled !== 'true');
    }, [currentSFXVolume, currentMusicVolume, profile]);
    useEffect(() => {
        if (profile) {
            setCurrentSFXVolume(Number.parseInt(profile.settings.volume_effects + ""));
            setCurrentMusicVolume(Number.parseInt(profile.settings.volume_music + ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile]);

    const toggleSound = () => {
        if (profile) {
            const newValue = (!(profile.settings.sound_enabled === 'true')).toString();
            updateProfileSetting("sound_enabled", newValue);
            profile.settings.sound_enabled = newValue;
            //Set sound volume with muted parameter to mute/unmute
            AdjustSFXVolume(currentSFXVolume / 100, newValue !== 'true');
            AdjustMusicVolume(currentMusicVolume / 100, newValue !== 'true');
        }
    }
    const toggleQuality = () => {
        if (profile) {
            let newQuality = 0.5;
            if (profile.settings.vfx_quality < 1) {
                newQuality = 1;
            }
            if (!changingQuality) {
                setChangingQuality(true)
                updateProfileSetting("vfx_quality", newQuality.toString()).then(v => {
                    //Reload whatever scene because unity needs to be restarted for pixel scaling changes to take effect
                    window.location.reload();
                });
            }
        }
    }
    return (
        <HoverMenu>
            <HoverMenuContent>
                <HoverMenuLink href="#GFXQuality" onClick={() => toggleQuality()}>GFX Quality
                    <br />
                    <ToggleableButton onTitle="High" offTitle="Low" isToggled={profile.settings.vfx_quality >= 1} />
                </HoverMenuLink>
            </HoverMenuContent>

            <HoverMenuContent>
                <HoverMenuLink href="#SoundToggle" onClick={(e) => { toggleSound(); }}>Sound
                    <br />
                    <ToggleableButton isToggled={profile.settings.sound_enabled === 'true'} />
                </HoverMenuLink>
            </HoverMenuContent>

            <HoverMenuContent>
                <HoverMenuLink href="#SFXVolume">
                    SFX Volume
                    <CustomSlider value={currentSFXVolume}
                        onChange={(e: any, v: any) => setCurrentSFXVolume(v)}
                        onChangeCommitted={(e, v) => {
                            updateProfileSetting("volume_effects", v.toString())
                        }}
                        style={{ paddingBottom: 0, maxWidth: "80%" }}></CustomSlider>
                </HoverMenuLink>
            </HoverMenuContent>
            <HoverMenuContent>
                <HoverMenuLink href="#MusicVolume">
                    Music Volume
                    <CustomSlider value={currentMusicVolume}
                        onChange={(e: any, v: any) => setCurrentMusicVolume(v)}
                        onChangeCommitted={(e, v) => {
                            updateProfileSetting("volume_music", v.toString())
                        }}
                        style={{ paddingBottom: 0, maxWidth: "80%" }}></CustomSlider>
                </HoverMenuLink>
            </HoverMenuContent>
            {Permissions.isAdminWhitelisted(account) &&
                < HoverMenuContent >
                    <HoverMenuLink href="/admin">Admin</HoverMenuLink>
                </HoverMenuContent>
            }
            <HoverMenuContent>
                <HoverMenuLink href="#logout" onClick={async () => { await Logout(Profile) }}>Log Out</HoverMenuLink>
            </HoverMenuContent>
        </HoverMenu>
    )
}