import BackgroundTrack from '../assets/sound/AnnexBackgroundTrack.mp3';
import Click_Primary from '../assets/sound/Click Sound 1.wav';
import Click_Secondary from '../assets/sound/Click Sound 2.wav';
import StartupTrack from '../assets/sound/HB Startup.mp3';

export interface GameSoundsType {
    BackgroundTrack: HTMLAudioElement,
    Click_Primary: HTMLAudioElement,
    Click_Secondary: HTMLAudioElement,
    StartupTrack: HTMLAudioElement
}

export const GameSounds: GameSoundsType = {
    BackgroundTrack: new Audio(BackgroundTrack),
    Click_Primary: new Audio(Click_Primary),
    Click_Secondary: new Audio(Click_Secondary),
    StartupTrack: new Audio(StartupTrack),
}


export const AdjustSFXVolume = (newVolume: number, muted: boolean) => {
    if (muted) { newVolume = 0 };
    GameSounds.Click_Primary.volume = newVolume;
    GameSounds.Click_Secondary.volume = newVolume;
}

export const AdjustMusicVolume = (newVolume: number, muted: boolean) => {
    if (muted) { newVolume = 0 };
    GameSounds.BackgroundTrack.volume = newVolume;
    GameSounds.StartupTrack.volume = newVolume;
}
