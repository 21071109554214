import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
// import MaintenancePage from './components/MaintenancePage';

import { Mainnet, DAppProvider, Config } from '@usedapp/core';
import ENDPOINTS from './service/EndpointsGlobal';
import ShutDown from 'components/ShutDown';

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]: ENDPOINTS.INFURA_MAINNET_URL,
  },
};

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={
            <App>
              <ShutDown />
            </App>
          }/>
        </Routes>
      </BrowserRouter>
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
