import { auth } from "./firebase_config"
import GLOBALS from "./EndpointsGlobal";

export const authorizedGetFetch = async (URL: string) => {
    const token = await auth.currentUser?.getIdToken();
    if (token) {
        return fetch(URL, {
            method: "GET",
            headers: {
                "Authorization": "" + token
            },
        }).catch(error => {
            alert("Something went wrong. Please try again.\nGET:" + URL + '\n\n' + error);
            throw error;
        })
    }
}

export const authorizedPostFetch = async (URL: string, body?: any) => {
    const token = await auth.currentUser?.getIdToken();
    if (token) {
        return fetch(URL, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "" + token
            },
        }).catch(error => {
            alert("Something went wrong. Please try again.\nPOST:" + URL + '\n\n' + error);
            throw error;
        })
    }
}

export const getSceneEvents = async (Scene: string) => {
    return authorizedGetFetch(`${GLOBALS.SCENE_API_URL}/${Scene}/events`);
}

export const getDialog = async (ref: string) => {
    return authorizedGetFetch(`${GLOBALS.SCENE_API_URL}/${ref}/dialog`);
}

export const updateProfileSetting = async (fieldName: string, settingValue: string) => {
    return authorizedPostFetch(GLOBALS.AUTH_URL + "/userSetting", { fieldName: fieldName, newValue: settingValue });
}

export interface CancellablePromise {
    promise: Promise<any>;
    cancel: () => void;
}
export const createCancellablePromise = (promise: Promise<any | void>) => {
    const isCancelled = { value: false };
    const wrappedPromise = new Promise((res, rej) => {
        promise
            .then(d => {
                return isCancelled.value ? rej(isCancelled) : res(d);
            })
            .catch(e => {
                rej(isCancelled.value ? isCancelled : e);
            });
    });
    const result = {
        promise: wrappedPromise,
        cancel: () => {
            isCancelled.value = true;
        }
    } as CancellablePromise;
    return result;
};