import React, { useEffect, useState } from "react";


const FontScaleComponent = (props: any) => {
    const [fontSize, setFontSize] = useState(100);
    useEffect(() => {
        setFontSize(getNewFontSizeInPercent(
            100, 1,
            props.changePerChar,
            props.startAtChar,
            props.children.length
        ))
    }, [props.changePerChar, props.startAtChar, props.stopAtChar, props.children.length])
    const getNewFontSizeInPercent = (
        maxPercent: number,
        minPercent: number,
        changePerChar: number,
        startAtChar: number,
        numberOfChars: number
    ): number => {
        const numberOfCharsAdjusted = numberOfChars;

        const numberOfCharToConsiderForCalculation = Math.max(
            0,
            numberOfCharsAdjusted - (startAtChar - 1)
        );

        const fontSizeInPercent = Math.max(
            minPercent,
            maxPercent - changePerChar * numberOfCharToConsiderForCalculation
        );
        return fontSizeInPercent;
    };


    return (
        <span style={{ fontSize: `${fontSize}%` }}>{props.children}</span>
    );
};

export default FontScaleComponent;
