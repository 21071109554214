import React from "react";
import styled from "styled-components";

type Props = {
    offTitle?: string;
    onTitle?: string;
    isToggled: boolean;
};

const ToggleButton = styled('div') <{ isSelected: boolean }>`
    font-weight:${props => props.isSelected ? 'bold' : '#999999'};;
    padding: min(0.3vw,0.6vh);
    flex:1;
    min-width: min(2.5vw,5vh);
    max-width: min(3.5vw,7vh);
    color: 'white';
    border-radius: min(0.5vw,1vh);
    border: min(0.2vw,0.4vh) solid #1976D2;
    
    &:first-child {
        border-right: none;
        border-top-right-radius:0;
        border-bottom-right-radius:0;
    }
    &:last-child {
        border-left: none;
        border-top-left-radius:0;
        border-bottom-left-radius:0;
    }
    div{
        background: ${props => props.isSelected ? '#1976D2' : 'transparent'};
        border-radius: min(0.5vw,1vh);
    }
`
const ButtonWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:center;
        
`

const ToggleableButton = (props: Props) => {

    return (
        <ButtonWrapper>
            <ToggleButton isSelected={!props.isToggled}>
                <div>{props.offTitle ?? "Off"}</div>
            </ToggleButton>
            <ToggleButton isSelected={props.isToggled}>
                <div>
                    {props.onTitle ?? "On"}
                </div>
            </ToggleButton>
        </ButtonWrapper>
    );
};

export default ToggleableButton;
